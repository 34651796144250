import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm$productCustoms$te, _vm$productCustoms$te2, _vm$data$touch$shopif, _vm$data$touch$shopif2, _vm$data$touch$shopif3;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('Loading', {
    attrs: {
      "value": _vm.processing,
      "modal": ""
    }
  }), _vm._v(" "), _c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-primary tw-mr-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1), _vm._v(" "), _c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('action.confirmationCampaign')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary ml-2",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('logout'))
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/logout'
        });
      }
    }
  })], 1), _vm._v(" "), _c('ContentArea', {
    attrs: {
      "set": _vm.labelNumber = 1
    }
  }, [_c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('campaignName')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(_vm._s(_vm.data.campaignName))]), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('touch')) + "\n    ")]), _vm._v(" "), _c('TouchSummary', {
    attrs: {
      "item": _vm.data.touch,
      "count-contacts": _vm.data.contacts.length
    },
    scopedSlots: _vm._u([{
      key: "text-prefix",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-caption secondary-text--text ml-2"
        }, [_vm._v("\n          " + _vm._s(_vm.$t('destinationNotes')) + "\n        ")])];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('destination')) + "\n    ")]), _vm._v(" "), _c('OutlinedTable', {
    staticClass: "disable-hover",
    attrs: {
      "headers": [{
        text: '氏名',
        value: 'name'
      }, {
        text: '敬称',
        value: 'title',
        empty: _vm.$t('notSet')
      }, {
        text: '会社名',
        value: 'account.name',
        getter: function getter(val) {
          var _val$account$name, _val$account;
          return (_val$account$name = (_val$account = val.account) === null || _val$account === void 0 ? void 0 : _val$account.name) !== null && _val$account$name !== void 0 ? _val$account$name : val.crm_company_name;
        },
        multiLine: true,
        empty: _vm.$t('notSet')
      }, {
        text: '部署',
        value: 'department',
        empty: _vm.$t('notSet')
      }, {
        text: '役職',
        value: 'job_title',
        empty: _vm.$t('notSet')
      }, {
        text: '所有者(担当者)',
        value: 'owner.name',
        empty: _vm.$t('notSet')
      }, {
        text: '最終送付日',
        value: 'last_sent',
        transform: _vm.$date,
        empty: _vm.$t('none')
      }, !_vm.isDigitalGift ? {
        text: '配送先住所',
        value: 'address',
        ellipsis: false
      } : null].filter(function (item) {
        return item != null;
      }),
      "items": _vm.complementedContacts,
      "item-key": "id",
      "fixed-last-column": !_vm.isDigitalGift
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('LinkText', {
          attrs: {
            "to": item.id ? "/contact/".concat(item.id) : null,
            "text": _vm.$fullName(item),
            "external": ""
          }
        })];
      }
    }, {
      key: "item.address",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm.data.selectedAddresses[item.crm_id] ? _c('AddressLabel', {
          attrs: {
            "small": "",
            "alert-color": "warning",
            "address": _vm.data.selectedAddresses[item.crm_id]
          }
        }) : _c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VIcon, {
          staticClass: "mr-2",
          attrs: {
            "size": "20",
            "color": "warning"
          }
        }, [_vm._v("mdi-alert")]), _vm._v(" "), _c('span', {
          staticClass: "text-body-2 warning--text"
        }, [_vm._v(_vm._s(_vm.$t('notSet')))])], 1)];
      }
    }])
  }), _vm._v(" "), !_vm.isDigitalGift && _vm.data.contacts.some(function (contact) {
    return !_vm.$isFillAddress(_vm.data.selectedAddresses[contact.crm_id]);
  }) ? _c('AddressAlert', {
    staticClass: "mt-4",
    attrs: {
      "address": null,
      "at-campaign": ""
    }
  }) : _vm._e(), _vm._v(" "), _c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    }
  }, [_vm._v("\n      " + _vm._s(_vm.isDigitalGift ? _vm.$t('issueDate') : _vm.$t('desiredArrivalDate')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n      " + _vm._s(_vm.$date(_vm.data.inhouseDate)) + "\n    ")]), _vm._v(" "), ((_vm$productCustoms$te = _vm.productCustoms.texts) === null || _vm$productCustoms$te === void 0 ? void 0 : _vm$productCustoms$te.length) > 0 || _vm.productCustoms.url ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    },
    scopedSlots: _vm._u([_vm.complementedContacts.length > 1 && _vm.hasContactPlaceholderText ? {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-gap-2 tw-self-end"
        }, [_c('span', {
          staticClass: "tw-shrink-0 tw-text-subtitle-2"
        }, [_vm._v("\n              " + _vm._s(_vm.$t('usePreviewDestination')) + " :\n            ")]), _vm._v(" "), _c('Input', {
          staticClass: "tw-flex-grow-0 tw-w-48 [&_fieldset]:tw-rounded-none [&_fieldset]:tw-border-0 [&_fieldset]:tw-border-b",
          attrs: {
            "type": "select",
            "items": _vm.complementedContacts,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-value": function itemValue(item) {
              return _vm.complementedContacts.indexOf(item);
            },
            "dense": "",
            "clearable": false
          },
          model: {
            value: _vm.textPreviewTargetIndex,
            callback: function callback($$v) {
              _vm.textPreviewTargetIndex = $$v;
            },
            expression: "textPreviewTargetIndex"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v("\n        " + _vm._s(_vm.$t('printedText')) + "\n        ")]), _vm._v(" "), ((_vm$productCustoms$te2 = _vm.productCustoms.texts) === null || _vm$productCustoms$te2 === void 0 ? void 0 : _vm$productCustoms$te2.length) > 0 ? _c('TouchTextViewer', {
    attrs: {
      "svg": _vm.data.svg,
      "shipping-targets": _vm.complementedContacts.map(function (contact) {
        return {
          contact: contact,
          shippingAddress: _vm.data.selectedAddresses[contact.crm_id]
        };
      }),
      "previewTargetIndex": _vm.textPreviewTargetIndex,
      "qr-url": _vm.data.url,
      "logo": _vm.company.image,
      "customs-texts": _vm.productCustoms.texts
    },
    model: {
      value: _vm.data.customTexts,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "customTexts", $$v);
      },
      expression: "data.customTexts"
    }
  }) : _vm._e(), _vm._v(" "), _vm.productCustoms.url ? _c('LabelText', {
    staticClass: "mt-6",
    attrs: {
      "text": _vm.data.url,
      "label": _vm.productCustoms.url
    }
  }) : _vm._e()] : _vm._e(), _vm._v(" "), _vm.hasGiftProduct ? [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": _vm.labelNumber++
    },
    scopedSlots: _vm._u([_vm.complementedContacts.length > 1 && _vm.hasContactPlaceholderGift ? {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tw-flex tw-items-center tw-gap-2 tw-self-end"
        }, [_c('span', {
          staticClass: "tw-shrink-0 tw-text-subtitle-2"
        }, [_vm._v("\n              " + _vm._s(_vm.$t('usePreviewDestination')) + " :\n            ")]), _vm._v(" "), _c('Input', {
          staticClass: "tw-flex-grow-0 tw-w-48 [&_fieldset]:tw-rounded-none [&_fieldset]:tw-border-0 [&_fieldset]:tw-border-b",
          attrs: {
            "type": "select",
            "items": _vm.complementedContacts,
            "item-text": function itemText(item) {
              return _vm.$fullName(item);
            },
            "item-value": function itemValue(item) {
              return _vm.complementedContacts.indexOf(item);
            },
            "dense": "",
            "clearable": false
          },
          model: {
            value: _vm.giftPreviewTargetIndex,
            callback: function callback($$v) {
              _vm.giftPreviewTargetIndex = $$v;
            },
            expression: "giftPreviewTargetIndex"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_vm._v("\n        " + _vm._s(_vm.$t('giftPage')) + "\n        ")]), _vm._v(" "), _c('GiftPageEditor', {
    attrs: {
      "value": _vm.data.lp,
      "logo": _vm.company.image,
      "shipping-targets": _vm.complementedContacts.map(function (contact) {
        return {
          contact: contact,
          shippingAddress: _vm.data.selectedAddresses[contact.id]
        };
      }),
      "previewTargetIndex": _vm.giftPreviewTargetIndex,
      "product-title": (_vm$data$touch$shopif = _vm.data.touch.shopify_product.display_gift_name) !== null && _vm$data$touch$shopif !== void 0 ? _vm$data$touch$shopif : _vm.data.touch.shopify_product.product_title,
      "product-image": (_vm$data$touch$shopif2 = _vm.data.touch.shopify_product.display_gift_image) !== null && _vm$data$touch$shopif2 !== void 0 ? _vm$data$touch$shopif2 : (_vm$data$touch$shopif3 = _vm.data.touch.shopify_product.images) === null || _vm$data$touch$shopif3 === void 0 ? void 0 : _vm$data$touch$shopif3[0]
    }
  })] : _vm._e()], 2), _vm._v(" "), _vm.isDigitalGift && _vm.crmName === 'Salesforce' ? _c('ContentArea', [_c('SectionSubTitle', {
    staticClass: "mb-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('shipping.selectWriteWowlink')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm.data.writeWowlink ? [_vm._v("\n        " + _vm._s(_vm.$t('extension.writeWowlink.writeTo', [_vm.crmName])) + "\n\n        "), _c('WriteWowlinkSetting', {
    attrs: {
      "value": _vm.data.writeWowlink,
      "status": _vm.data.writeWowlinkSettingStatus,
      "readonly": ""
    }
  })] : _c('span', {
    staticClass: "disabled-text--text"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('extension.writeWowlink.notWriteTo', [_vm.crmName])) + "\n      ")])], 2)], 1) : _vm._e(), _vm._v(" "), !_vm.canCreateOrder ? _c('ContentArea', {
    staticClass: "d-flex align-center"
  }, [_c('h6', {
    staticClass: "flex-shrink-0"
  }, [_vm._v(_vm._s(_vm.$t('authorizer')))]), _vm._v(" "), _c('Input', {
    staticClass: "ml-6 flex-grow-0",
    attrs: {
      "type": "autocomplete",
      "items": _vm.teamManagers,
      "item-text": "name",
      "item-value": "email"
    },
    model: {
      value: _vm.data.assigned_to,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "assigned_to", $$v);
      },
      expression: "data.assigned_to"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [_vm.canCreateOrder ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCreateCampaign'))
    },
    on: {
      "click": function click($event) {
        return _vm.createOrder(true);
      }
    }
  }) : _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "type": "button",
      "disabled": !_vm.data.assigned_to
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('action.doCreateCampaign'))
    },
    on: {
      "click": function click($event) {
        return _vm.createOrder(false);
      }
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary mt-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.back')) + "\n    ")])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };